import { FirebaseApp, initializeApp } from "firebase/app";

import { User, getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import React, { useState, useEffect, createContext, Context, PropsWithChildren } from "react";

var firebaseConfig = {
    apiKey: "AIzaSyBSitdIIunTlPgDxArmpGWgLnY-Uf5_Qpc",
    authDomain: "wpplink.firebaseapp.com",
    databaseURL: "https://wpplink.firebaseio.com",
    projectId: "wpplink",
    storageBucket: "wpplink.appspot.com",
    messagingSenderId: "648051935925",
    appId: "1:648051935925:web:1550bc6a9cc5a7a8",
};

const wppApp = initializeApp(firebaseConfig);

export const app = wppApp;

export const appContext: Context<FirebaseApp | undefined> = createContext<FirebaseApp | undefined>(wppApp);

export const FirebaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [anonymusUser, setAnonymusUser] = useState<User | undefined>(undefined);

    const authApp = getAuth(wppApp);
    const unsubscribe = onAuthStateChanged(authApp, (user) => {
        console.log("teste aa");
        if (user) {
            console.log("teste bb");
            console.log(user);
            setAnonymusUser(user);
        }
    });
    useEffect(() => {
        signInAnonymously(authApp);
    }, [anonymusUser]);

    return anonymusUser ? (
        <appContext.Provider value={wppApp}> {children} </appContext.Provider>
    ) : (
        <div>CARREGANDO...</div>
    );
};
