import React, { PropsWithChildren } from "react";

import "../App.scss";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="app">
            <div className="container">
                <div className="columns link-view">
                    <div className="column has-text-justified is-half-desktop is-offset-one-quarter-desktop">
                        <h3 className="is-size-3 blog has-text-white has-text-weight-bold has-text-centered">
                            Sobre o gerador de link curto What's Link
                        </h3>
                        <div className="has-text-white container-help container-news">
                            <p>
                                Criado em 2018, o What's Link é uma ferramenta online e gratuita para qualquer pessoa ou
                                negócio. O site existe para encurtar o caminho do seu amigo/cliente com você, com um
                                link personalizado que leva o usuário direto para seu WhatsApp além de trazer um blog de
                                tecnologia incluso, trazendo novidades do mundo digital.
                            </p>
                             
                            <p>
                                Todos os conteúdos e dados deste site são apenas para fins informativos, não devem ser
                                considerados completos, atualizados, e não se destinam a ser utilizado no lugar de uma
                                consulta jurídica, médica, financeira, ou de qualquer outro profissional. Os conteúdos
                                são fornecidos sem qualquer tipo de garantia. Todo e qualquer risco da utilização dos
                                conteúdos é assumido pelo próprio usuário.
                            </p>
                            <br></br>
                            <p>Desde 2018 o What's Link é mantido e gerenciado pela empresa Nuvem, Inc.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
