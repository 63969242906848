import React, { useContext, useState, useEffect } from "react";
import { appContext } from "../fireapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { child, get, getDatabase, ref } from "firebase/database";
interface Link {
    count: number;
}

interface LinkRouteProps {
    match: {
        params: {
            [k: string]: string;
        };
    };
}
const DOMAINDB = process.env.REACT_APP_DATABASE || "";

const NotFound: React.FC = () => {
    return <h1 className="is-size-3 has-text-weight-semibold has-text-white"> WhatsApp Link não encontrado. </h1>;
};

const LinkDetail: React.FC<{ link: Link }> = ({ link }) => {
    return (
        <div>
            <h3 className="subtitle has-text-weight-semibold has-text-white"> Quantidade de links: </h3>
            <h1 className="title has-text-weight-semibold has-text-white"> {link.count} </h1>
            <p></p>
        </div>
    );
};

const CountScreen: React.FC<LinkRouteProps> = () => {
    const app = useContext(appContext);

    const [result, setResult] = useState<{
        founded: boolean;
        link: Link;
    }>();

    const fetchLink = async function () {
        if (!app) {
            return;
        }
        const database = getDatabase(app);
        const platformref = ref(database, "plataformas");
        const domain = child(platformref, DOMAINDB);
        const ds = await get(domain);
        setResult({
            founded: ds.exists(),
            link: ds.val(),
        });
    };
    useEffect(() => {
        console.log("fazendo a consulta");
        fetchLink().catch((err) => {
            console.log(err);
        });
    }, [app]);
    return (
        <div className="container">
            <div className="columns link-view">
                <div className="column has-text-centered is-half-desktop is-offset-one-quarter-desktop">
                    {result && result.founded ? (
                        <LinkDetail link={result.link} />
                    ) : (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            size="lg"
                            className="has-text-white is-size-1"
                            spin
                            pulse
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CountScreen;
