import React from "react";

export class AdsCleverCore extends React.Component {
    async componentDidMount() {
        (function (document: any, window: any) {
            var a,
                c = document.createElement("script"),
                f = window.frameElement;

            c.id = "CleverCoreLoader67255";
            c.src = "https://scripts.cleverwebserver.com/b53deebe44e6f64d8d91a598eec855ca.js";

            c.async = !0;
            c.type = "text/javascript";
            c.setAttribute("data-target", window.name || (f && f.getAttribute("id")));

            try {
                a = parent.document.getElementsByTagName("script")[0] || document.getElementsByTagName("script")[0];
            } catch (e) {
                a = !1;
            }

            a || (a = document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]);
            a.parentNode.insertBefore(c, a);
        })(document, window);
    }

    render() {
        return <div></div>;
    }
}

export class AdsComponent extends React.Component<{ slot: string }> {
    async componentDidMount() {
        (window.adsbygoogle || []).push({});
    }

    render() {
        return (
            <ins
                className="adsbygoogle"
                style={{ display: "block"}}
                data-ad-client="ca-pub-2241525781831625"
                data-ad-slot={this.props.slot}
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            // <ins className="adsbygoogle"
            //     style={{ display: 'block' }}
            //     data-ad-client="ca-pub-8011765208303106"
            //     data-ad-slot="2393735332"
            //     data-ad-format="auto"
            //     data-full-width-responsive="true"></ins>
            // data-ad-test="on"
        );
    }
}
