import React, { useContext, useState, useEffect } from "react";
import { appContext } from "../fireapp";
import { child, get, getDatabase, ref } from "firebase/database";

const DOMAINDB = process.env.REACT_APP_DATABASE || "";

interface Window {
    eval(f: string): void;
}

interface AdsCustom {
    content: string;
    script: string;
}

export const AdsComponent: React.FC = () => {
    const app = useContext(appContext);

    const [adsenseval, setAdsenseval] = useState<AdsCustom | null>(null);

    const fetchAdsenseval = async function () {
        if (!app) {
            return;
        }
        const database = getDatabase(app);
        const platformref = ref(database, "plataformas");
        const ds = await get(child(platformref, `${DOMAINDB}/adsense`));
        setAdsenseval(ds.val());
    };

    useEffect(() => {
        fetchAdsenseval().catch((err) => {
            console.log("failed to getch adsense");
            console.log(err);
        });
    }, [app]);
    useEffect(() => {
        if (!document || !adsenseval) {
            return;
        }
        // Create the element
        console.log(`adding adsense script: ${adsenseval.script}`);
        var script = document.createElement("script");

        // Add script content

        script.innerHTML = adsenseval.script;

        // Append

        setTimeout(() => document.head.appendChild(script), 200);
    }, [adsenseval]);
    return (
        <ins
            className="adswhatslink"
            style={{ display: "block" }}
            data-ad-format="auto"
            data-full-width-responsive="true">
            <div
                id="ins-ads-custom-script"
                dangerouslySetInnerHTML={{
                    __html: adsenseval ? adsenseval.content : "",
                }}></div>
        </ins>
        // data-ad-test="on"
    );
};
