import React, { PropsWithChildren } from "react";

import "../App.scss";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="app">
            <div className="container">
                <div className="columns link-view">
                    <div className="column has-text-justified is-half-desktop is-offset-one-quarter-desktop">
                        <h3 className="is-size-3 blog has-text-white has-text-weight-bold has-text-centered">
                            Fale Conosco
                        </h3>
                        <div className="has-text-white container-help container-news">
                            <p>
                                Entre em contato, envie críticas, sugestões, pedidos ou informações sobre nosso portal.
                                <br></br>
                                <br></br>
                                Click e fale com a{" "}
                                <a
                                    href="http://whats.link/nuvem"
                                    className="has-text-weight-bold">
                                    Nuvem, Inc.
                                </a>
                            </p>
                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
