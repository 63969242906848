import React, { PropsWithChildren } from "react";

import "../App.scss";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="app">
            <div className="container">
                <div className="columns link-view">
                    <div className="column has-text-justified is-half-desktop is-offset-one-quarter-desktop">
                        <h3 className="is-size-3 blog has-text-white has-text-weight-bold has-text-centered">
                            Termo de Uso
                        </h3>
                        <div className="has-text-white container-help container-news">
                            <p>
                                Ao acessar ao What's Link você deve estar ciente e de acordo com os termos de uso aqui
                                estabelecidos. Caso não esteja de acordo com os termos, por favor, não utilize o site. O
                                What's Link se reserva ao direito de alterar a qualquer momento os termos de uso.
                            </p>
                             
                            <p>
                                O What's Link garante acesso limitado apenas aos que estiverem de acordo e cumprirem os
                                termos de uso. O What's Link se reserva ao direito de limitar o acesso a qualquer um, a
                                qualquer momento e por qualquer motivo.
                            </p>
                            <br></br>
                            <p>
                                Todos os conteúdos deste site são apenas para fins informativos, não devem ser
                                considerados completos, atualizados, e não se destinam a ser utilizado no lugar de uma
                                consulta jurídica, médica, financeira, ou de qualquer outro profissional. Os conteúdos
                                são fornecidos sem qualquer tipo de garantia. Todo e qualquer risco da utilização dos
                                conteúdos é assumido pelo próprio usuário.
                            </p>
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
