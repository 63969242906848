import React, { PropsWithChildren } from "react";

import "../App.scss";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="app">
            <div className="container">
                <div className="columns link-view">
                    <div className="column has-text-justified is-half-desktop is-offset-one-quarter-desktop">
                        <h3 className="is-size-3 blog has-text-white has-text-weight-bold has-text-centered">
                            Política de Privacidade
                        </h3>
                        <div className="has-text-white container-help container-news">
                            <p>O What's Link, reconhece que a privacidade é importante.</p> 
                            <p>
                                O What's Link recebe e mantém informações de seu browser nos nossos servidores,
                                incluindo seu IP ou endereço de referência e a página que você procurou. Além desta
                                informação, a única outra informação pessoal que nós coletamos sobre você é a que nos
                                for fornecida em nossos formulários online. O What's Link será a única detentora desta
                                informação. Esta informação somente será usada para nosso acompanhamento estatístico do
                                número de visitas.
                            </p>
                            <br></br>
                            <p>
                                O site usa cookies e outras tecnologias para melhorar a sua experiência on-line e para
                                saber como você usa nossos serviços, com a finalidade de melhorar a qualidade deles.
                            </p>
                            <br></br>
                            <p>
                                Usamos empresas de publicidade de terceiros para veicular anúncios durante a sua visita
                                ao nosso website. Essas empresas podem usar informações (que não incluem o seu nome,
                                endereço, endereço de e-mail ou número de telefone) sobre suas visitas a este e a outros
                                websites a fim de exibir anúncios relacionados a produtos e serviços de seu interesse.
                                Para obter mais informações sobre essa prática e saber como impedir que as empresas
                                utilizem esses dados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
