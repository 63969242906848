import React from "react";

interface NotFoundComponentProps {
    link: string;
}

const NotFoundComponent: React.FC<NotFoundComponentProps> = ({ link }) => {
    return (
        <div>
            <h4 className="has-text-white is-size-4 ">Link não encontrado</h4>
        </div>
    );
};

export default NotFoundComponent;
